import React from "react"
import PropTypes from "prop-types"
import { groupBy } from "lodash"
import * as shortid from "shortid"
import "lazysizes"

import { Link, graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import SEO from "../components/SEO"
import { localizedSlugPrefix } from "../gatsby/nodeHelpers"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"

import "../styles/proceduresPage.scss"

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.common.black,

    "&:hover": {
      color: theme.palette.aresBlue.main,
    },
  },
  expanded: {
    "&$expanded": {
      margin: "0 auto",
      minHeight: 0,
    },
  },
  procedureIcon: {
    marginRight: 15,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center left",
    color: "transparent",
    width: 60,
    height: 60,
  },
  summaryExpanded: {
    marginTop: "0 !important",
    marginBottom: "0 !important",
    minHeight: "0 !important",
  },
  expandIcon: {
    color: "#fff",
  },
  panel: {
    marginBottom: 5,
  },

  summaryContent: {
    margin: 0,
    alignItems: "center",
  },
  panelHeader: {
    background: theme.palette.aresBlue.main,
    padding: "0 10px",
    margin: 0,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center right",
  },

  panelDetails: {
    background: "#f0f0f0",
  },
  headingText: {
    color: "#fff",
    fontWeight: 600,
    flex: 1,
    lineHeight: 1.1,
    fontSize: '1.2rem'
  },

  group: {
    listStyle: "none",
  },
  groupLi: {
    position: "relative",
    "&::before": {
      content: `'•'`,
      color: "#3dabda",
      fontWeight: "bold",
      display: "inline-block",
      width: "1em",
      marginLeft: "-1em",
      position: "absolute",
      top: 3,
      fontSize: 30,
      lineHeight: "20px",
    },
  },
}))

const ProceduresPage = ({
  data: { proceduresPage, procedureCategories, procedures },
  pageContext: { locale, slug, breadcrumbs },
  location,
}) => {
  const classes = useStyles()

  const schema = proceduresPage.data.schema_json.text || ""
  const ogp = proceduresPage.data.ogp_tags || ""
  const meta = {
    title: proceduresPage.data.meta_title,
    description: proceduresPage.data.meta_description,
    keywords: proceduresPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  const procs = procedures.edges.map(r => r.node)

  const categories = procedureCategories.edges.map(r => r.node)

  const procedureGroups = groupBy(procs, p => p.data.categories[0].category.id)

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionTitle component="h1">
                {proceduresPage.data.title}{" "}
                <span style={{ fontWeight: 700 }}>MONZA ARES</span>
              </SectionTitle>
            </Grid>

            <Grid item xs={12}>
              {categories.map(category => (
                <Accordion
                  square
                  key={shortid.generate()}
                  className={classes.panel}
                  classes={{ expanded: classes.expanded }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="procedurePanelHeader"
                    classes={{
                      content: classes.summaryContent,
                      expanded: classes.summaryExpanded,
                      expandIcon: classes.expandIcon,
                    }}
                    style={{
                      backgroundImage: `url(${category.data.display_image.gatsbyImageData.images.fallback.src})`,
                    }}
                  >
                    <div
                      className={classes.procedureIcon}
                      style={{
                        backgroundImage: `url(${category.data.icon.gatsbyImageData.images.fallback.src})`,
                      }}
                    >
                      Icon
                    </div>
                    <Typography className={classes.headingText}>
                      {category.data.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.panelDetails}>
                    <ul className={classes.group}>
                      {procedureGroups[category.prismicId] &&
                        procedureGroups[category.prismicId].map(procedure => (
                          <li
                            className={classes.groupLi}
                            key={shortid.generate()}
                          >
                            <Link
                              className={classes.link}
                              to={`${localizedSlugPrefix(procedure)}/${slug}/${category.uid
                                }/${procedure.uid}/`}
                            >
                              {procedure.data.title.text}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  )
}

export default ProceduresPage

export const query = graphql`
  query ProceduresPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    proceduresPage: prismicProcedures(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      first_publication_date
      last_publication_date
      data {
        title
        description {
          text
        }

        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }

    procedureCategories: allPrismicProcedureCategory(
      sort: { order: ASC, fields: data___order_number }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          uid
          id
          prismicId
          lang
          data {
            name
            display_image {
              gatsbyImageData(layout: FIXED, width: 460, height: 65)
            }
            icon {
              gatsbyImageData(layout: FIXED, width: 60, height: 65)
            }
          }
          lang
        }
      }
    }
    procedures: allPrismicProcedure(
      sort: { fields: data___order_number, order: ASC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          href
          lang
          uid
          data {
            title {
              text
            }
            categories {
              category {
                id
                url
                slug
              }
            }
          }
        }
      }
    }
  }
`

ProceduresPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    proceduresPage: PropTypes.object.isRequired,
    procedureCategories: PropTypes.object.isRequired,
    procedures: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
